<template>
  <div>
    <!-- Section main banner -->
    <section id="home" class="parallax-fixed main-banner" data-stellar-background-ratio="0.5">
      <div class="content-table">
        <div class="content-middle">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-7 col-lg-7 text-center text-md-left wow zoomIn" data-wow-duration=".2s"
                data-wow-delay=".1s">
                <h1 class="text-white text-capitalize strong mt-0">
                  It's Free, Simple & Easy
                </h1>
                <p class="lead text-white">
                  Payment made free, simple and easy, everywhere and anytime for
                  People
                </p>
                <!-- <a href="#"><img src="/assets/images/google-play.png" class="img-fluid mr-md-3 mb-3" width="180" alt="" /></a>
                                <a href="#"><img class="img-fluid mb-3" width="180" src="/assets/images/app-store.png" alt="" /></a> -->
              </div>
              <div class="
                                                col-md-4 col-lg-3 col-xl-4
                                                text-center text-md-left
                                                ml-auto
                                                wow
                                                fadeInRight
                                              " data-wow-duration=".2s" data-wow-delay=".1s">
                <img class="img-fluid" src="/assets/images/mobile-1.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Section main banner -->
    <!-- Section App Feature -->
    <section>
      <div class="container">
        <div class="row">
          <div class="col-lg-8 ml-auto mr-auto text-center wow fadeInUp" data-wow-duration=".2s" data-wow-delay=".1s">
            <h2 class="text-uppercase">OVERVIEW</h2>
            <p>
              Free, simple and easy payment application for individuals,
              businesses and organizations.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-lg-4 text-center wow zoomIn" data-wow-duration=".2s" data-wow-delay=".1s">
            <div class="icon-block mb-md-5 mb-sm-4">
              <i class="lnr lnr-diamond"></i>
              <h4>Send-Receive</h4>
              <p style="text-align: justify">
                Instantly, send money to your love ones, employees and suppliers
                or receive money from clients and family. It's free, simple and
                easy.
              </p>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 text-center wow zoomIn" data-wow-duration=".3s" data-wow-delay=".2s">
            <div class="icon-block mb-md-5 mb-sm-4">
              <i class="lnr lnr-enter-down"></i>
              <h4>Wallet To Bank</h4>
              <p style="text-align: justify">
                Easily transfer money from your Mobile Wallet to your Bank
                Account. It's even simple when transfering from wallet to
                someone's Bank Account.
              </p>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 text-center wow zoomIn" data-wow-duration=".4s" data-wow-delay=".3s">
            <div class="icon-block mb-md-5 mb-sm-4">
              <i class="lnr lnr-rocket"></i>
              <h4>Bank To Wallet</h4>
              <p style="text-align: justify">
                Easily transfer money from your Bank Account to your Mobile
                Wallet. It's even simple when transfering from Bank Account to
                someones's Mobile Wallet
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-lg-4 text-center wow zoomIn" data-wow-duration=".5s" data-wow-delay=".4s">
            <div class="icon-block mb-md-0 mb-sm-4">
              <i class="lnr lnr-cog"></i>
              <h4>Cash To Card</h4>
              <p style="text-align: justify">
                Easily transfer money from your mobile or Bank Account to your
                Visa/Master Card. Or easily receive remittance directly to you
                Visa/Master Card.
              </p>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 text-center wow zoomIn" data-wow-duration=".6s" data-wow-delay=".5s">
            <div class="icon-block mb-md-0 mb-sm-4">
              <i class="lnr lnr-laptop-phone"></i>
              <h4>Utility Bills</h4>
              <p style="text-align: justify">
                Easily pay your utility bills(electricity, water, internet,
                etc...) effortlessy in the comfort of your home. You don't need
                to waste hours in a long queue.
              </p>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 text-center wow zoomIn" data-wow-duration=".7s" data-wow-delay=".6s">
            <div class="icon-block">
              <i class="lnr lnr-alarm"></i>
              <h4>Airtime & Data</h4>
              <p style="text-align: justify">
                Easily buy airtime & data bundle for yourself or your loves one
                with simple payment steps. Everytime and everywhere, have
                sufficient airtime and data bundle.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Section App Feature -->
    <!-- Section About -->
    <section id="about" class="bg-primary">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 ml-auto mr-auto text-center text-white wow fadeInUp" data-wow-duration=".2s"
            data-wow-delay=".1s">
            <h2 class="text-white text-uppercase">About</h2>
            <p>
              Yns is a free, simple and easy payment application for serving
              and helping individuals, businesses and organizations in day to
              day financial transactions.
            </p>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="
                                            col-lg-6 col-md-8
                                            ml-md-auto
                                            mr-md-auto
                                            col-xl-5
                                            text-center
                                            wow
                                            zoomIn
                                          " data-wow-duration=".3s" data-wow-delay=".2s">
            <div class="row swiper-container">
              <div class="swiper-wrapper">
                <div data-page-number="1" class="swiper-slide">
                  <img src="/assets/images/appscreen2.jpg" class="img-fluid" alt="" />
                </div>
                <div data-page-number="2" class="swiper-slide">
                  <img src="/assets/images/appscreen1.jpg" class="img-fluid" alt="" />
                </div>
                <div data-page-number="3" class="swiper-slide">
                  <img src="/assets/images/appscreen4.jpg" class="img-fluid" alt="" />
                </div>
                <div data-page-number="4" class="swiper-slide">
                  <img src="/assets/images/appscreen3.jpg" class="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>

          <div class="col-lg-6 ml-auto text-white wow zoomIn" data-wow-duration=".3s" data-wow-delay=".2s">
            <h3 class="text-tertiary text-center text-lg-left">WHY Yns?</h3>
            <p class="text-center text-lg-left" style="text-align: justify">
              Gone are the days of carrying money from home to bank or
              travelling thousands miles to receive payment from a debitor. Time
              wasted and lives put at risk in the course of money transaction,
              are all revolutionized with technology. In the era of
              digitalization like ours, financial transactions should be as
              free, easy and simple as Yns.
            </p>

            <!-- <div class="text-center text-lg-left">
                            <p><a href="#" class="btn btn-cta btn-lg">DOWNLOAD NOW</a></p>
                        </div> -->
          </div>
        </div>
      </div>
    </section>
    <!-- End Section About -->

    <!-- Section Features -->
    <section id="features">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 ml-auto mr-auto text-center">
            <h2>FEATURES</h2>
            <p>
              Using Yns, as individual, business or organization is awesome
              with important features that satisfy almost all financial
              transaction needs
            </p>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-4 text-center text-lg-right">
            <div class="service-box icon-right mb-xs-4 wow zoomIn" data-wow-duration=".2s" data-wow-delay=".1s">
              <i class="lnr lnr-thumbs-up"></i>
              <h4 class="m-0 mb-2">Easy Steps</h4>
              <p>
                With easy steps, sign up and link your Mobile Wallet, your Bank
                Account, your visa/master card to your Yns account
              </p>
            </div>
            <div class="service-box icon-right mb-xs-4 wow zoomIn" data-wow-duration=".4s" data-wow-delay=".2s">
              <i class="lnr lnr-mic"></i>
              <h4 class="m-0 mb-2">Secure Transaction</h4>
              <p>
                Sign up, sign in, and send or receive money in a very secure
                way. All credentials and important information are encrypted.
              </p>
            </div>
            <div class="service-box icon-right mb-xs-4 wow zoomIn" data-wow-duration=".6s" data-wow-delay=".4s">
              <i class="lnr lnr-text-format"></i>
              <h4 class="m-0 mb-2">Instant Transaction</h4>
              <p>
                Money is instantly transfered the moment you send. The
                beneficiary instantly receive notification as accounts are
                credited and debited accordingly.
              </p>
            </div>
          </div>
          <div class="col-lg-4 text-center mb-xs-4 wow zoomIn" data-wow-duration=".3s" data-wow-delay=".2s">
            <p>
              <img class="img-fluid" src="/assets/images/mobile.png" alt="" />
            </p>
          </div>
          <div class="col-lg-4 text-center text-lg-left">
            <div class="service-box icon-left mb-xs-4 wow zoomIn" data-wow-duration=".4s" data-wow-delay=".2s">
              <i class="lnr lnr-magic-wand"></i>
              <h4 class="m-0 mb-2">Free of Charge</h4>
              <p>
                Every transaction is free. Either you send or receive money, or
                transfer money between Bank Account and Mobile Wallet, it's
                still free.
              </p>
            </div>
            <div class="service-box icon-left mb-xs-4 wow zoomIn" data-wow-duration=".6s" data-wow-delay=".4s">
              <i class="lnr lnr-lock"></i>
              <h4 class="m-0 mb-2">Fast & reliable</h4>
              <p>
                Complexity is taken out to make operations simple and fast.
                Application crash is also taken care of no matter the number of
                operations.
              </p>
            </div>
            <div class="service-box icon-left wow zoomIn" data-wow-duration=".8s" data-wow-delay=".6s">
              <i class="lnr lnr-coffee-cup"></i>
              <h4 class="m-0 mb-2">24/7 Support</h4>
              <p>
                Our support team is always available to assist with guidance and
                clarification on the available functionalities.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Section Features -->
    <!-- Section Intro -->
    <section>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 ml-auto ml-auto wow zoomIn" data-wow-duration=".5s" data-wow-delay=".2s">
            <img class="img-fluid" src="/assets/images/phone.png" alt="" />
          </div>
          <div class="col-lg-6 ml-auto mr-auto wow zoomIn" data-wow-duration=".5s" data-wow-delay=".2s">
            <h2 class="text-capitalize h1">
              Send money around the globe
            </h2>
            <p>
              No need to deposit money into your Bank Account, after that,
              transfer the money to another bank account, and another Bank
              Account again, or passing money from one friend to the other
              before money getting to your supplier. Now,
              it's one simple step: From Yns to your supplier Mobile Wallet or
              Bank Account directly.
            </p>
            <div class="service-xs-box icon-block icon-left mb-xs-4">
              <i class="lnr lnr-thumbs-up"></i>
              <p>
                Now you can transfer money instantly and directly to the
                beneficiary account.
              </p>
            </div>
            <div class="service-xs-box icon-block icon-left mb-xs-4">
              <i class="lnr lnr-magic-wand"></i>
              <p>
                Now you can easily send money directlry to your love ones in
                China and Hong and continue taking care of them like never
                before
              </p>
            </div>
            <!-- <p><a href="#" class="btn btn-primary btn-lg">Download Now</a></p> -->
          </div>
        </div>
      </div>
    </section>
    <!-- End Section Intro -->

    <!-- Section Intro -->
    <section class="bg-light">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 ml-auto order-md-2 wow zoomIn" data-wow-duration=".5s" data-wow-delay=".2s">
            <img class="img-fluid" src="/assets/images/phone-r.png" alt="" />
          </div>
          <div class="
                                            col-lg-6
                                            mr-auto
                                            text-left text-lg-right
                                            order-md-1
                                            wow
                                            zoomIn
                                          " data-wow-duration=".5s" data-wow-delay=".2s">
            <h2 class="text-capitalize h1">
              Transfer Money Between Mobile Wallet & Bank Account
            </h2>
            <p></p>
            <div class="service-xs-box icon-block icon-right mb-xs-4">
              <i class="lnr lnr-thumbs-up"></i>
              <p>
                Transfer money from your Mobile Wallet to your Bank Account and
                easily disburse money
              </p>
            </div>
            <div class="service-xs-box icon-block icon-right mb-xs-4">
              <i class="lnr lnr-magic-wand"></i>
              <p>
                It's even more easier to transfer money from your Bank Account
                to your Mobile Wallet for quick and secure payment
              </p>
            </div>
            <!-- <p><a href="#" class="btn btn-primary btn-lg">Download Now</a></p> -->
          </div>
        </div>
      </div>
    </section>
    <!-- End Section Intro -->
    <!-- Section App Screenshorts -->
    <section id="screenshorts" class="bg-white">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 ml-auto mr-auto text-center">
            <h2 class="text-uppercase">Screenshorts</h2>
            <p>
              Simple and easy steps to efficiently use Yns for payment and
              money transfer
            </p>
          </div>
        </div>

        <div class="app-screenshots center-align owl-carousel owl-theme row">
          <div class="card item shadow20 wow fadeIn" data-wow-duration=".2s" data-wow-delay=".1s">
            <img class="img-fluid" src="/assets/images/appscreen2.jpg" alt="" />
          </div>
          <div class="card item wow fadeIn" data-wow-duration=".3s" data-wow-delay=".2s">
            <img class="img-fluid" src="/assets/images/appscreen1.jpg" alt="" />
          </div>

          <div class="card item wow fadeIn" data-wow-duration=".4s" data-wow-delay=".3s">
            <img class="img-fluid" src="/assets/images/appscreen3.jpg" alt="" />
          </div>
          <div class="card item wow fadeIn" data-wow-duration=".5s" data-wow-delay=".4s">
            <img class="img-fluid" src="/assets/images/appscreen4.jpg" alt="" />
          </div>
          <div class="card item wow fadeIn" data-wow-duration=".5s" data-wow-delay=".4s">
            <img class="img-fluid" src="/assets/images/appscreen5.jpg" alt="" />
          </div>
          <div class="card item wow fadeIn" data-wow-duration=".5s" data-wow-delay=".4s">
            <img class="img-fluid" src="/assets/images/appscreen6.jpg" alt="" />
          </div>
          <div class="card item wow fadeIn" data-wow-duration=".5s" data-wow-delay=".4s">
            <img class="img-fluid" src="/assets/images/appscreen7.jpg" alt="" />
          </div>
          <div class="card item wow fadeIn" data-wow-duration=".5s" data-wow-delay=".4s">
            <img class="img-fluid" src="/assets/images/appscreen8.jpg" alt="" />
          </div>
        </div>
      </div>
    </section>
    <!-- End App Screenshorts -->
    <!-- Section videos -->
    <section id="videos" class="parallax-fixed bg-videos text-white" data-stellar-background-ratio="0.5">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 ml-auto mr-auto text-center wow fadeIn" data-wow-duration=".2s" data-wow-delay=".1s">
            <h2 class="text-white text-uppercase">Watch Video Introduction</h2>
            <p>Clic the play button below to know more about Yns</p>
            <p>
              <a class="video-popup" href="https://www.youtube.com/watch?v=GUurzvS3DlY">
                <img class="img-fluid" src="/assets/images/play-icon.png" alt="" />
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- End videos -->

    <!-- Section FAQs -->
    <section class="bg-light">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 ml-auto mr-auto text-center">
            <h2 class="text-uppercase">Frequently Asked Questions?</h2>
            <p>
              We provide answers to some questions you migh be asking about Yns
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 mr-auto wow zoomIn" data-wow-duration=".5s" data-wow-delay=".2s">
            <img class="img-fluid" src="/assets/images/phone.png" alt="" />
          </div>
          <div class="col-lg-6 ml-auto wow zoomIn" data-wow-duration=".5s" data-wow-delay=".2s">
            <div class="accordion panel-group" id="faq">
              <div class="card">
                <div class="card-header">
                  <a data-toggle="collapse" data-parent="#faq" aria-expanded="true" href="#collapse2">
                    How much is the charge or fee for sending money using Yns?
                  </a>
                </div>
                <div class="collapse show" id="collapse2" style="">
                  <div class="card-body">
                    <p>
                      It's free. Everything on Yns is free. We don't charge
                      anything for sending money or transfering money from
                      Mobile Wallet to Bank Account and vice-versa.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <a data-toggle="collapse" data-parent="#faq" aria-expanded="false" href="#collapse3" class="collapsed">
                    Can I send or transfer money to other countries using Yns?
                  </a>
                </div>
                <div class="collapse" id="collapse3">
                  <div class="card-body">
                    <p>
                      Yes, you can send or transfer money to other countries and
                      oversea. Especially, you can send or tranfer money to a
                      family or business partner in China or Hong Kong.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <a data-toggle="collapse" data-parent="#faq" aria-expanded="false" href="#collapse4" class="collapsed">
                    Can I pay utility bills or buy airtime and data bundle using
                    Yns?
                  </a>
                </div>
                <div class="collapse" id="collapse4">
                  <div class="card-body">
                    <p>
                      Yes, you can. Paying utilitity bills or buying airtime and
                      data bundle using Yns is possible through simple and easy
                      steps.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End FAQs -->
    <!-- Section Download App -->
    <section class="bg-white">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 ml-auto mr-auto text-center wow fadeIn" data-wow-duration=".4s" data-wow-delay=".2s">
            <h5>Now Available On</h5>
            <h2 class="text-uppercase">Download Yns</h2>
            <p>
              You can download Yns from all the app stores on all your mobile
              devices wether Android or iOS.
            </p>
            <!-- <p>
                            <a href="#"><img src="/assets/images/google-play.png" class="img-fluid mr-md-3 mb-3" width="180" alt="" /></a>
                            <a href="#"><img class="img-fluid mb-3" width="180" src="/assets/images/app-store.png" alt="" /></a>
                        </p> -->
            <p class="mb-0 wow zoomIn" data-wow-duration=".4s" data-wow-delay=".2s">
              <img class="img-fluid" src="/assets/images/app-screen.png" alt="" />
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- End Download App -->
    <!-- Section Contact -->
    <section id="contact" class="bg-dark text-white">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 ml-auto mr-auto text-center wow fadeInUp">
            <h2 class="text-white text-uppercase">Get In Touch</h2>
            <p>
              For enquiry or any feedback, we'll be happy to read from you. Feel
              free to contact us!
            </p>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-md-12 col-xl-10 mr-auto ml-auto">
            <div class="row">
              <div class="col-md-6 col-lg-6 wow zoomIn" data-wow-duration=".5s" data-wow-delay=".2s">
                <div class="address-block mb-2">
                  <!-- <i class="lnr lnr-map-marker"></i>
                  <p class="lead"><br /></p> -->
                </div>
                <div class="address-block mb-2">
                  <i class="lnr lnr-envelope"></i>
                  <p class="lead">
                    info@Yns.daragroups.com<br />
                    support@Yns.daragroups.com
                  </p>
                </div>
                <div class="address-block">
                  <!-- <i class="lnr lnr-phone-handset"></i> -->
                  <p class="lead"><br /></p>
                </div>
              </div>
              <div class="col-md-6 col-lg-6 wow fadeInUp">
                <form @submit.prevent="sendMessage" method="post">
                  <div class="form-group">
                    <input type="text" v-model="userContact.fullName" class="form-control" placeholder="Full Name" />
                    <div class="text-danger" v-if="errors.fullName">
                      {{ errors.fullName }}
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="text" v-model="userContact.email" class="form-control" placeholder="E-mail Address" />
                    <div class="text-danger" v-if="errors.email">
                      {{ errors.email }}
                    </div>
                  </div>
                  <div class="form-group">
                    <textarea class="form-control" v-model="userContact.message" placeholder="Your Message" rows="5"
                      cols="4"></textarea>
                    <div class="text-danger" v-if="errors.message">
                      {{ errors.message }}
                    </div>
                  </div>
                  <div class="d-flex justify-content-start mb-4">
                    <input type="submit" class="btn btn-cta text-uppercase btn-xl" value="Submit" />
                  </div>
                  <div class="text-success" v-if="messageRes">
                    {{ messageRes }}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Contact -->
  </div>
</template>

<script>
import axios from "axios";
import validator from "validator";

export default {
  name: "Home",
  data() {
    return {
      errors: {},
      messageRes: null,
      userContact: {
        fullName: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    sendMessage() {
      this.errors = {};
      if (validator.isEmpty(this.userContact.fullName))
        this.errors.fullName = "Full name is required!";
      if (validator.isEmpty(this.userContact.email))
        this.errors.email = "Email is required!";
      if (!validator.isEmail(this.userContact.email))
        this.errors.email = "Email must be valid!";
      if (validator.isEmpty(this.userContact.message))
        this.errors.message = "Message is required!";
      if (!Object.keys(this.errors).length) {
        let self = this;
        axios
          .post(`${process.env.VUE_APP_API}/api/send/mail`, this.userContact)
          .then((res) => {
            self.messageRes = res.data.message;
            self.resetForm();
          })
          .catch((err) => { });
      }
    },
    resetForm() {
      this.userContact = {};
    },
  },
};
</script>
