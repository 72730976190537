<template>
    <header class="header">
        <!-- Navbar -->
        <nav class="navbar navbar-expand-lg navbar-sticky">
            <div class="container">
                <router-link class="navbar-brand" to="/"><img class="img-fluid" src="/assets/images/logo.png" alt=""></router-link>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarCollapse">
                    <ul class="navbar-nav ml-auto mr-auto">
                        <li class="nav-item"><a class="nav-link" data-scroll href="#home">Home</a></li>
                        <li class="nav-item"><a class="nav-link" data-scroll href="#about">About</a></li>
                        <li class="nav-item"><a class="nav-link" data-scroll href="#features">Features</a></li>
                        <li class="nav-item"><a class="nav-link" data-scroll href="#screenshorts">Screenshorts</a></li>
                        <li class="nav-item"><a class="nav-link" data-scroll href="#contact">Contact</a></li>
                    </ul>
                    <a href="#" class="btn btn-light btn-rounded d-none d-lg-block">Get It Now</a>
                </div>
            </div>
        </nav>
        <!-- End Navbar -->
    </header>
    <!-- End Header -->
</template>
<script>
export default {
    name: 'Header'
}
</script>